* {
  box-sizing: border-box;
}

body,
h1,
h2 {
  margin: 0;
  padding: 0;
}

body {
  /* font-family: Open Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol'; */
  font-family: Helvetica;
  -webkit-font-smoothing: antialiased;
}

h2 {
  color: #21243d;
  font-family: Hind, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Header */

.header {
  align-items: center;
  background-position: center;
  background-size: cover;
  color: #10111d80;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-height: 100px;
  padding: 0.5rem 1rem;
  text-align: center;
}

.header-logo {
  margin: 0;
}

.header-logo svg {
  height: 24px;
  width: 92px;
}

.header-title {
  font-size: 38px;
  font-weight: 300;
}

/* Containers */

.container {
  display: flex;
  margin: 0 auto;
  max-width: 1600px;
  padding: 1rem 2rem;
}

.container-filters {
  flex: 1;
  margin-right: 35px;
  max-width: 260px;
}

.container-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 44px;
}

.container-results {
  flex: 3;
}

.container-options {
  border-bottom: 1px solid #ebecf3;
  display: flex;
  justify-content: flex-end;
  padding: 15px 0;
}

.container-options .container-option:not(:first-child) {
  margin-left: 48px;
}

.container-options select {
  min-width: 100px;
}

.container-footer {
  margin: 4rem 0;
}

/* Styles the SFFV highlightings */

em {
  font-style: normal;
}

em,
mark {
  background: rgba(226, 164, 0, 0.4);
}

/* Clear refinements container */

.clear-filters {
  align-items: center;
  display: flex;
}

.clear-filters svg {
  margin-right: 8px;
}

/* Panel */

.container-body .ais-Panel {
  border-top: 1px solid #ebecf3;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.ais-Panel-header {
  font-family: Hind, sans-serif;
}

/* Search box */

.header .ais-SearchBox {
  display: flex;
}

.header .ais-SearchBox .ais-SearchBox-input {
  background-color: #fff;
  border: 1px solid #ebecf3;
  font-family: Hind, sans-serif;
  /*
    The "Hind" font family is vertically off-balance.
    Adding 4px of padding top makes it more vertically aligned.
  */
  padding: 4px 48px 0 64px;
}

.header .ais-SearchBox-submit {
  padding: 0 1rem 0 2rem;
  width: 64px;
}

.header .ais-SearchBox .ais-SearchBox-input::placeholder {
  color: rgba(33, 36, 61, 0.24);
  opacity: 1; /* Firefox */
}

.ais-SearchBox-input:-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.24);
}

.ais-SearchBox-input::-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.24);
}

.ais-SearchBox-submit {
  color: #21243d80;
}

.ais-RefinementList .ais-SearchBox-input {
  font-family: Hind, sans-serif;
  /*
    The "Hind" font family is vertically off-balance.
    Adding some padding top makes it more vertically aligned.
  */
  padding-top: 2px;
}

/* Hits */

.hit {
  color: #21243d;
  font-size: 14px;
  line-height: 20px;
}

.ais-Hits-item {
  /* border-bottom: 1px solid #efefef; */
}

.hit h1 {
  font-size: 14px;
  font-weight: 500;
}

.hit-category {
  color: #21243d;
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 8px;
  opacity: 0.7;
  text-transform: uppercase;
}
.flickity-slider {
  width: 104%;
}

.hit-store {
  text-transform: uppercase;
  margin-right: 13px;
  /* border-bottom: 1px solid #efefef; */
  display: flex;
  justify-content: center;
}

a:active,
a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

.hit-store__text {
  margin-left: 5px;
  font-weight: 600;
  font-size: 12px;
}

.hit-description {
  margin-top: 2px;
}

.hit-info-container {
  overflow-wrap: break-word;
  word-break: break-word;
  text-align: center;
  margin: 10px;
}

.hit-image-container {
  align-items: center;
  display: flex;
  height: 400px;
  justify-content: center;
  margin: auto;
  /* border-bottom: 1px solid #efefef; */
  width: 300px;
}

.ais-Highlight {
  /* font-size: 0.9rem !important */
}

.hit-image {
  height: auto;
  max-height: 100%;
  max-width: 100%;
}

.hit-footer {
  font-size: 0.72rem;
  display: flex;
  font-weight: 500;
  justify-content: center;
}

.login {
  display: flex;
}

a {
  text-decoration: none;
  color: inherit;
}

.hit-em {
  font-size: 11px;
  font-weight: 600;
}

.hit-rating {
  border: 1px solid rgba(226, 164, 0, 0.5);
  margin-left: 4px;
  padding: 0 4px;
}

.hits-empty-state {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 300px;
}

.hits-empty-state-title {
  font-family: Hind;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
}

.hits-empty-state-description {
  color: rgba(35, 37, 51, 0.6);
  font-size: 0.875rem;
  text-align: center;
}

.hits-empty-state .ais-ClearRefinements {
  margin-top: 1rem;
}

.hits-empty-state .ais-ClearRefinements-button--disabled {
  display: none;
}

.hits-empty-state .ais-ClearRefinements-button {
  background: rgba(10, 8, 41, 0.04);
  /* border-radius: 3px; */
  color: #21243d;
  min-height: 48px;
  padding: 16px 24px;
}

.hit__views {
  align-items: center;
  display: flex;
  /* flex-direction: column; */
  /* margin: 5px; */
  justify-content: center;
}

.likes__section {
  align-items: center;
  display: flex;
  font-weight: 400;
  /* flex-direction: column; */
  margin-top: -1px;
  font-size: 0.8rem;
  justify-content: center;
}

.sc-bdnylx {
  margin: -30px;
}

/* ToggleRefinement */

.ais-ToggleRefinement-label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.ais-ToggleRefinement-checkbox {
  font: inherit;
  margin-left: 1rem;
  margin-right: 0;
  position: relative;
}

.ais-ToggleRefinement-checkbox:checked::before {
  color: #e2a400;
}

.ais-ToggleRefinement-checkbox::before {
  align-items: center;
  color: rgba(33, 36, 61, 0.32);
  content: 'No';
  display: flex;
  font-size: 0.8rem;
  height: 16px;
  position: absolute;
  right: 38px;
}

.ais-ToggleRefinement-checkbox:checked::before {
  content: 'Yes';
}

.ais-ToggleRefinement-count {
  display: none;
}

/* RatingMenu */

.ais-RatingMenu-item:not(.ais-RatingMenu-item--selected) {
  opacity: 0.5;
}

.ais-RatingMenu-starIcon {
  margin-right: 0.5rem;
}

/* Hide all mobile-specific design on desktop */

@media (min-width: 900px) {
  [data-layout='mobile'] {
    display: none;
  }
}

time {
  text-transform: uppercase;
  color: #8e8e8e;
  font-size: 10px;
}

.MuiSvgIcon-root {
  width: 1.2em !important;
  height: 1.2em !important;
  cursor: pointer;
}

.icon-button {
  display: inline-block;
  height: 32px;
  width: 32px;
  padding: 5px;
  margin-top: -4px;
  margin-bottom: -5px;
  background-size: 22px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-origin: content-box;
  cursor: pointer;
}

.icon-button--obsessed {
  background-image: url('/like.svg?vsn=d');
  background-repeat: no-repeat;
}

.icon-button--obsessed:hover {
  background-image: url('/like_hover.svg?vsn=d');
}

.icon-button--sign-in {
  background-image: url('/user.svg');
  background-repeat: no-repeat;
}

.icon-button--sign-in:hover {
  background-image: url('/user_hover.svg');
  background-repeat: no-repeat;
}

.icon-button--sign-out {
  background-image: url('/logout.svg');
  background-repeat: no-repeat;
}

.icon-button--sign-out:hover {
  background-image: url('/logout_hover.svg');
  background-repeat: no-repeat;
}

.nav--controls {
  display: flex;
}

.modal2-window {
  z-index: 100;
  display: none;
}

.modal2-window.active {
  display: block;
}

.modal2-window.modal2-window--about {
  width: 700px;
}

.modal2-window.modal2-window--about h1 {
  padding: 20px;
  text-align: center;
  text-transform: none;
}

.modal2-window.modal2-window--about .button,
.modal2-window.modal2-window--about .sign-up-header-banner__button,
.modal2-window.modal2-window--about .category-page .white-button-black-borders,
.category-page .modal2-window.modal2-window--about .white-button-black-borders,
.modal2-window.modal2-window--about .business-sellers__button,
.modal2-window.modal2-window--about .business-sellers__cta-button {
  margin: 20px;
}

.modal-window,
.modal2-window {
  background-color: #fff;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  opacity: 1;
  overflow-y: auto;
  position: relative;
  width: 884px;
  transition-property: opacity;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
}

.modal-window .modal-header,
.modal2-window .modal-header {
  padding: 10px 10px 0 10px;
}

.modal-window .modal-header:after,
.modal2-window .modal-header:after {
  content: '';
  display: table;
  clear: both;
}

.modal-window .modal-header.dark,
.modal2-window .modal-header.dark {
  background-color: #ccc;
  border-bottom: 1px solid #999;
  padding-bottom: 10px;
}

.modal-window .modal-header.dark + .modal-body,
.modal2-window .modal-header.dark + .modal-body {
  margin-top: 20px;
}

.modal-window .modal-body,
.modal2-window .modal-body {
  margin: 20px 20px 20px 30px;
}

.modal-window .modal-body h2,
.modal2-window .modal-body h2 {
  font-size: 21px;
  color: #333;
}

.modal-window .modal-body #my-size-clothing,
.modal-window .modal-body #my-size-shoes,
.modal-window .modal-body #mens-lengths-grid,
.modal2-window .modal-body #my-size-clothing,
.modal2-window .modal-body #my-size-shoes,
.modal2-window .modal-body #mens-lengths-grid {
  font-size: 12px;
  color: #333;
}

.modal-window .modal-body input,
.modal2-window .modal-body input {
  background-color: #fff;
  border: 1px solid #595959;
}

.modal-window .modal-body input:hover,
.modal2-window .modal-body input:hover {
  background-color: #fff;
}

.modal-window button.close,
.modal2-window button.close {
  line-height: 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
  background-color: #333;
  color: #fff !important;
  font-weight: normal;
  padding: 7px 10px;
  font-size: 12px;
  text-transform: uppercase;
  border: 0 solid #ccc;
  border-top-width: 0;
  border-bottom-width: 0;
  outline: none;
  text-align: center !important;
  margin-left: 10px;
  float: right;
  padding: 2px 7px 3px 7px;
  text-transform: lowercase;
}

.modal-window button.close.disabled,
.modal2-window button.close.disabled {
  background: #ccc;
}

.modal-window button.close:hover,
.modal2-window button.close:hover {
  color: #eee !important;
  text-decoration: none;
  background-image: none;
}

.modal-window form,
.modal2-window form {
  width: 100%;
}

.modal-window.hidden,
.modal2-window.hidden {
  display: block;
  opacity: 0 !important;
  z-index: -100 !important;
}

.modal2-window--small {
  width: 620px;
}

.modal2-window--medium {
  width: 1000px;
}

.modal-background,
.modal2-background {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 16200000;
  background-color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 760px) {
  .modal-background[data-mobile-placement='bottom'],
  .modal2-background[data-mobile-placement='bottom'] {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}

.or-horizontal-separator {
  border-bottom: 1px solid #ccc;
  font-family: Cardo-Italic;
  margin: 12px 0;
  overflow: visible;
  position: relative;
  width: 100%;
}

.or-horizontal-separator:before {
  background-color: #fff;
  color: #999;
  content: 'or';
  font-size: 16px;
  left: 50%;
  margin-left: -11px;
  position: absolute;
  text-align: center;
  top: -0.65em;
  width: 22px;
}

@media screen and (max-width: 760px) {
  .or-horizontal-separator {
    margin: 16px 0;
  }
}

.sign-in-sign-up-modal-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 340px;
  padding-bottom: 10px;
}

.sign-in-sign-up-modal-wrapper.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 760px) {
  .sign-in-sign-up-modal-wrapper.active {
    width: 90%;
  }
}

.sign-in-sign-up-modal-wrapper .sign-in-sign-up-modal-wrapper__content {
  display: block;
  min-height: 485px;
}

.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .modal-header {
  padding: 0;
}

.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .modal-header.post-apple-sign-in {
  width: 100%;
  text-align: center;
}

.sign-in-sign-up-modal-wrapper__close-button {
  position: absolute;
  right: -7px;
  top: -7px;
  margin: 10px;
}

.sign-in-sign-up-modal-wrapper--uncloseable {
  width: 100vw;
}

.sign-in-sign-up-modal-wrapper--uncloseable
  .sign-in-sign-up-modal-wrapper__close-button {
  display: none;
}

.login-page,
.sign-in-sign-up-modal-wrapper .sign-in-sign-up-modal-wrapper__content {
  padding-top: 17px;
  font-size: 14px;
  max-width: 390px;
}

@media screen and (max-width: 760px) {
  .login-page,
  .sign-in-sign-up-modal-wrapper .sign-in-sign-up-modal-wrapper__content {
    width: 100%;
  }
}

.login-page .modal-body,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .modal-body {
  margin: 0;
}

.login-page .sign-up-body__wrapper,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-up-body__wrapper {
  margin: 0 30px;
}

@media screen and (max-width: 760px) {
  .login-page .sign-up-body,
  .login-page .sign-in-body,
  .sign-in-sign-up-modal-wrapper
    .sign-in-sign-up-modal-wrapper__content
    .sign-up-body,
  .sign-in-sign-up-modal-wrapper
    .sign-in-sign-up-modal-wrapper__content
    .sign-in-body {
    margin-top: 0;
  }
}

.login-page .sign-up-body .form-title,
.login-page .sign-in-body .form-title,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-up-body
  .form-title,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body
  .form-title {
  font-size: 18px;
  font-weight: bold;
  font-variant-numeric: lining-nums;
  text-align: center;
  text-transform: uppercase;
  color: rgb(16, 16, 16);
}

.login-page .sign-in-body,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body {
  margin: 0 30px;
}

.login-page .sign-in-body input:focus,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body
  input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.login-page .sign-in-body input[type='submit'],
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body
  input[type='submit'] {
  margin-top: 0;
}

.login-page .sign-up-body__form-field,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-up-body__form-field {
  margin: 0.5em 0;
}

@media screen and (max-width: 760px) {
  .login-page .sign-up-body__form-field,
  .sign-in-sign-up-modal-wrapper
    .sign-in-sign-up-modal-wrapper__content
    .sign-up-body__form-field {
    margin: 0.25em 0;
  }
}

.login-page .armoire-logo,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .armoire-logo {
  margin: 0 10px 10px;
  text-align: center;
}

.login-page .armoire-logo-tagline,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .armoire-logo-tagline {
  padding: 2px;
  margin-bottom: 10px;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: center;
  color: #101010;
  background-color: #f5f5f5;
}

.login-page .armoire-logo-img,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .armoire-logo-img {
  max-width: 198px;
}

@media screen and (max-width: 760px) {
  .login-page .armoire-logo-img,
  .sign-in-sign-up-modal-wrapper
    .sign-in-sign-up-modal-wrapper__content
    .armoire-logo-img {
    max-width: 60%;
  }
}

@media screen and (max-height: 667px) {
  .sign-in-sign-up-modal-wrapper[data-google-login-enabled] .armoire-logo {
    margin-bottom: 0;
  }

  .sign-in-sign-up-modal-wrapper[data-google-login-enabled]
    .reg-gate-fine-print {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .sign-in-sign-up-modal-wrapper[data-google-login-enabled]
    .sign-in-sign-up-modal-wrapper__floating-footer {
    display: none;
  }
}

.google-sign-in-button {
  margin-top: 10px;
}

.google-sign-in-button .oauth-sign-in-button__logo {
  background-image: url('/logo_google.svg?vsn=d');
  height: 1.4em;
  width: 1.4em;
}

.oauth-sign-in-button {
  font-family: Helvetica, Arial;
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  text-align: center !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 30px;
  padding: 0 10px;
  color: #101010;
  line-height: 28px;
  transition-property: all;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-duration: 0.15s;
  -webkit-transition-duration: 0.15s;
  -moz-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 40px;
  width: 100%;
  line-height: 40px;
  max-width: 450px;
  letter-spacing: 1px;
  font-size: 12px;
  position: relative;
  border: #101010 1px solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 336px) {
  .oauth-sign-in-button {
    letter-spacing: 0;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.oauth-sign-in-button:hover,
.oauth-sign-in-button:focus {
  opacity: 0.7;
}

.oauth-sign-in-button .oauth-sign-in-button__logo {
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 6px;
}

.oauth-sign-in-button:hover {
  text-decoration: none;
}

.sign-in-sign-up-modal-wrapper__floating-footer a {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8em;
  color: #333;
  margin-right: 15px;
  margin-left: 15px;
}

html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.button--primary,
.sign-up-header-banner__button {
  font-weight: normal !important;
  background: #333 !important;
  color: #fff !important;
  border: 1px solid #666 !important;
}

.button--primary:focus:not([disabled]),
.sign-up-header-banner__button:focus:not([disabled]),
.button--primary:hover:not([disabled]),
.sign-up-header-banner__button:hover:not([disabled]) {
  background: #fff !important;
  color: #333 !important;
}

.button--ghost {
  font-weight: normal !important;
  background: rgba(51, 51, 51, 0.7) !important;
  color: #fff !important;
  border: 1px solid rgba(51, 51, 51, 0.7) !important;
}

.button--ghost:hover {
  background: rgba(255, 255, 255, 0.7) !important;
  color: #333 !important;
}

.formtastic-form__action--submit .button,
.formtastic-form__action--submit .sign-up-header-banner__button,
.formtastic-form__action--submit .category-page .white-button-black-borders,
.category-page .formtastic-form__action--submit .white-button-black-borders,
.formtastic-form__action--submit .business-sellers__button,
.formtastic-form__action--submit .business-sellers__cta-button {
  font-size: 16px;
  width: 356px;
  height: 53px !important;
  line-height: 0px !important;
}

ul,
ol {
  list-style: none;
}

.sign-in-sign-up-modal-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 340px;
  padding-bottom: 10px;
}

.sign-in-sign-up-modal-wrapper.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 760px) {
  .sign-in-sign-up-modal-wrapper.active {
    width: 90%;
  }
}

.sign-in-sign-up-modal-wrapper .sign-in-sign-up-modal-wrapper__content {
  display: block;
  min-height: 485px;
}

.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .modal-header {
  padding: 0;
}

.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .modal-header.post-apple-sign-in {
  width: 100%;
  text-align: center;
}

.sign-in-sign-up-modal-wrapper__close-button {
  position: absolute;
  right: -7px;
  top: -7px;
  margin: 10px;
}

.sign-in-sign-up-modal-wrapper--uncloseable {
  width: 100vw;
}

.sign-in-sign-up-modal-wrapper--uncloseable
  .sign-in-sign-up-modal-wrapper__close-button {
  display: none;
}

.login-page,
.sign-in-sign-up-modal-wrapper .sign-in-sign-up-modal-wrapper__content {
  padding-top: 17px;
  font-size: 14px;
  max-width: 390px;
}

@media screen and (max-width: 760px) {
  .login-page,
  .sign-in-sign-up-modal-wrapper .sign-in-sign-up-modal-wrapper__content {
    width: 100%;
  }
}

.login-page .modal-body,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .modal-body {
  margin: 0;
}

.login-page .sign-up-body__wrapper,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-up-body__wrapper {
  margin: 0 30px;
}

@media screen and (max-width: 760px) {
  .login-page .sign-up-body,
  .login-page .sign-in-body,
  .sign-in-sign-up-modal-wrapper
    .sign-in-sign-up-modal-wrapper__content
    .sign-up-body,
  .sign-in-sign-up-modal-wrapper
    .sign-in-sign-up-modal-wrapper__content
    .sign-in-body {
    margin-top: 0;
  }
}

.login-page .sign-up-body input[type='email'],
.login-page .sign-up-body input[type='password'],
.login-page .sign-in-body input[type='email'],
.login-page .sign-in-body input[type='password'],
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-up-body
  input[type='email'],
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-up-body
  input[type='password'],
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body
  input[type='email'],
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body
  input[type='password'] {
  font-style: italic;
}

.login-page .sign-up-body .form-title,
.login-page .sign-in-body .form-title,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-up-body
  .form-title,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body
  .form-title {
  font-family: 'Austin Text';
  font-size: 18px;
  font-weight: bold;
  font-variant-numeric: lining-nums;
  text-align: center;
  text-transform: uppercase;
}

.login-page .sign-in-body,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body {
  margin: 0 30px;
}

.login-page .sign-in-body input:focus,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body
  input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.login-page .sign-in-body input[type='submit'],
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body
  input[type='submit'] {
  margin-top: 0;
}

.login-page .sign-up-body__form-field,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-up-body__form-field {
  margin: 0.5em 0;
}

@media screen and (max-width: 760px) {
  .login-page .sign-up-body__form-field,
  .sign-in-sign-up-modal-wrapper
    .sign-in-sign-up-modal-wrapper__content
    .sign-up-body__form-field {
    margin: 0.25em 0;
  }
}

.login-page .armoire-logo,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .armoire-logo {
  margin: 0 10px 10px;
  text-align: center;
}

.login-page .armoire-logo__post-apple-sign-in,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .armoire-logo__post-apple-sign-in {
  border-radius: 22.5%;
  background-image: url('/images/apple-touch-icons/apple-touch-icon-a3484a80fe16cb3c275192b7c363a6fe.png?vsn=d');
  background-size: cover;
  height: 74px;
  width: 74px;
  margin: 0 auto 36px;
}

.login-page .armoire-logo-tagline,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .armoire-logo-tagline {
  padding: 2px;
  margin-bottom: 10px;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: center;
  color: #101010;
  background-color: #f5f5f5;
}

.login-page .armoire-logo-img,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .armoire-logo-img {
  max-width: 198px;
}

@media screen and (max-width: 760px) {
  .login-page .armoire-logo-img,
  .sign-in-sign-up-modal-wrapper
    .sign-in-sign-up-modal-wrapper__content
    .armoire-logo-img {
    max-width: 60%;
  }
}

@media screen and (max-height: 667px) {
  .sign-in-sign-up-modal-wrapper[data-google-login-enabled]
    .armoire-logo-tagline {
    display: none;
  }

  .sign-in-sign-up-modal-wrapper[data-google-login-enabled] .armoire-logo {
    margin-bottom: 0;
  }

  .sign-in-sign-up-modal-wrapper[data-google-login-enabled]
    .reg-gate-fine-print {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .sign-in-sign-up-modal-wrapper[data-google-login-enabled]
    .sign-in-sign-up-modal-wrapper__floating-footer {
    display: none;
  }
}

.font-size-body,
.formtastic-form__input--combo_box .combo-box__no-results {
  letter-spacing: 0.02em;
  font-size: 14px;
  line-height: 1.6em;
}

.login-page .modal-body,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .modal-body {
  margin: 0;
}

.sign-in-sign-up-modal-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 340px;
  padding-bottom: 10px;
}

.sign-in-sign-up-modal-wrapper.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 760px) {
  .sign-in-sign-up-modal-wrapper.active {
    width: 90%;
  }
}

.sign-in-sign-up-modal-wrapper .sign-in-sign-up-modal-wrapper__content {
  display: block;
  min-height: 485px;
}

.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .modal-header {
  padding: 0;
}

.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .modal-header.post-apple-sign-in {
  width: 100%;
  text-align: center;
}

.sign-in-sign-up-modal-wrapper__close-button {
  position: absolute;
  right: -7px;
  top: -7px;
  margin: 10px;
}

.sign-in-sign-up-modal-wrapper--uncloseable {
  width: 100vw;
}

.sign-in-sign-up-modal-wrapper--uncloseable
  .sign-in-sign-up-modal-wrapper__close-button {
  display: none;
}

.login-page,
.sign-in-sign-up-modal-wrapper .sign-in-sign-up-modal-wrapper__content {
  padding-top: 17px;
  font-size: 14px;
  max-width: 390px;
}

@media screen and (max-width: 760px) {
  .login-page,
  .sign-in-sign-up-modal-wrapper .sign-in-sign-up-modal-wrapper__content {
    width: 100%;
  }
}

.login-page .modal-body,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .modal-body {
  margin: 0;
}

.login-page .sign-up-body__wrapper,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-up-body__wrapper {
  margin: 0 30px;
}

@media screen and (max-width: 760px) {
  .login-page .sign-up-body,
  .login-page .sign-in-body,
  .sign-in-sign-up-modal-wrapper
    .sign-in-sign-up-modal-wrapper__content
    .sign-up-body,
  .sign-in-sign-up-modal-wrapper
    .sign-in-sign-up-modal-wrapper__content
    .sign-in-body {
    margin-top: 0;
  }
}

.login-page .sign-up-body input[type='email'],
.login-page .sign-up-body input[type='password'],
.login-page .sign-in-body input[type='email'],
.login-page .sign-in-body input[type='password'],
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-up-body
  input[type='email'],
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-up-body
  input[type='password'],
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body
  input[type='email'],
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body
  input[type='password'] {
  font-style: italic;
}

.login-page .sign-up-body .form-title,
.login-page .sign-in-body .form-title,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-up-body
  .form-title,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body
  .form-title {
  font-family: 'Austin Text';
  font-size: 18px;
  font-weight: bold;
  font-variant-numeric: lining-nums;
  text-align: center;
  text-transform: uppercase;
}

.login-page .sign-in-body,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body {
  margin: 0 30px;
}

.login-page .sign-in-body input:focus,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body
  input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.login-page .sign-in-body input[type='submit'],
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-in-body
  input[type='submit'] {
  margin-top: 0;
}

.login-page .sign-up-body__form-field,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .sign-up-body__form-field {
  margin: 0.5em 0;
}

@media screen and (max-width: 760px) {
  .login-page .sign-up-body__form-field,
  .sign-in-sign-up-modal-wrapper
    .sign-in-sign-up-modal-wrapper__content
    .sign-up-body__form-field {
    margin: 0.25em 0;
  }
}

.login-page .armoire-logo,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .armoire-logo {
  margin: 0 10px 10px;
  text-align: center;
}

.login-page .armoire-logo__post-apple-sign-in,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .armoire-logo__post-apple-sign-in {
  border-radius: 22.5%;
  background-image: url('/images/apple-touch-icons/apple-touch-icon-a3484a80fe16cb3c275192b7c363a6fe.png?vsn=d');
  background-size: cover;
  height: 74px;
  width: 74px;
  margin: 0 auto 36px;
}

.login-page .armoire-logo-tagline,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .armoire-logo-tagline {
  padding: 2px;
  margin-bottom: 10px;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: center;
  color: #101010;
  background-color: #f5f5f5;
}

.login-page .armoire-logo-img,
.sign-in-sign-up-modal-wrapper
  .sign-in-sign-up-modal-wrapper__content
  .armoire-logo-img {
  max-width: 198px;
}

@media screen and (max-width: 760px) {
  .login-page .armoire-logo-img,
  .sign-in-sign-up-modal-wrapper
    .sign-in-sign-up-modal-wrapper__content
    .armoire-logo-img {
    max-width: 60%;
  }
}

@media screen and (max-height: 667px) {
  .sign-in-sign-up-modal-wrapper[data-google-login-enabled]
    .armoire-logo-tagline {
    display: none;
  }

  .sign-in-sign-up-modal-wrapper[data-google-login-enabled] .armoire-logo {
    margin-bottom: 0;
  }

  .sign-in-sign-up-modal-wrapper[data-google-login-enabled]
    .reg-gate-fine-print {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .sign-in-sign-up-modal-wrapper[data-google-login-enabled]
    .sign-in-sign-up-modal-wrapper__floating-footer {
    display: none;
  }
}

.formtastic-form__action--submit {
  /* display:-webkit-box; */
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.button,
.sign-up-header-banner__button,
.category-page .white-button-black-borders,
.business-sellers__cta-button,
.business-sellers__button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  transition-property: all;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-duration: 0.15s;
  -webkit-transition-duration: 0.15s;
  -moz-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 0.7em;
  display: inline-block !important;
  vertical-align: top !important;
  font-family: Helvetica, Arial !important;
  text-transform: uppercase !important;
  cursor: pointer;
  outline: none !important;
  text-align: center !important;
  height: 4.4em !important;
  padding: 0 14px !important;
  line-height: 4.4em !important;
  overflow: hidden;
}

.login-page,
.sign-in-sign-up-modal-wrapper .sign-in-sign-up-modal-wrapper__content {
  padding-top: 17px;
  font-size: 14px;
  max-width: 390px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.anonymous-login-description {
  margin: 15px 0px;
  font-size: 0.9em;
}

.reg-gate-fine-print {
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 15px;
  color: #999;
  font-size: 0.8em;
  line-height: 15px;
  margin-top: 15px;
  overflow-wrap: break-word;
  text-align: center;
}

.reg-gate-fine-print-link {
  color: inherit;
}

.reg-gate-fine-print-link:link,
.reg-gate-fine-print-link:visited {
  text-decoration: underline;
}

.generic-pages__wrapper {
  width: 93%;
  max-width: 910px;
  margin: 0 auto 20px;
}

.generic-pages__wrapper p {
  font-family: Helvetica, Arial;
  padding: 10px 0;
  letter-spacing: 0.02em;
}

.generic-pages__wrapper a {
  font-weight: 500;
  text-decoration: underline;
}

.generic-pages__wrapper h1 {
  font-family: Helvetica, Arial;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 40px;
  color: #101010;
  text-transform: uppercase;
  text-align: center;
  margin: 20px 20px;
  font-family: Helvetica, Arial;
}

@media screen and (max-width: 760px) {
  .generic-pages__wrapper h1 {
    font-size: 28px;
    line-height: 36px;
  }
}

.generic-pages__wrapper h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Helvetica, Arial;
  font-weight: 800;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.generic-pages__wrapper h3 {
  margin-bottom: 10px;
  font-size: 15px;
  font-family: Helvetica, Arial;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: #333;
}

.generic-pages__wrapper ul {
  margin: 20px 0 0 40px;
  list-style-type: disc;
}

.generic-pages__wrapper .generic-pages__my_purchases h3 {
  padding-top: 5px;
}

.generic-pages__wrapper .generic-pages__my_purchases p {
  display: inline;
  padding-right: 0.25em;
}

.generic-pages__wrapper .generic-pages__text-bold {
  padding: 20px 0 5px;
  font-weight: 600;
}

.generic-pages__wrapper.press {
  font-size: 14px;
}

.generic-pages__wrapper.press .press-item {
  display: table;
  width: 100%;
  margin-bottom: 20px;
}

.generic-pages__wrapper.press .press-item .press-item-image-container {
  display: table-cell;
  vertical-align: middle;
  width: 190px;
  text-align: center;
}

.generic-pages__wrapper.press .press-item p {
  font-family: Helvetica, Arial;
  color: #333;
  line-height: 1.4em;
  word-spacing: 1px;
  padding: 2px 0;
}

.generic-pages__wrapper.press .press-item p a {
  color: #a22;
  text-decoration: none;
}

.generic-pages__content-container section {
  margin-bottom: 45px;
}

.generic-pages__section {
  margin-bottom: 35px;
}

.generic-pages__section .generic-pages__sub-header {
  margin-bottom: 10px;
  font-family: Helvetica, Arial;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
}

.generic-pages__section .generic-pages__title {
  margin-bottom: 10px;
  font-size: 15px;
  font-family: Helvetica, Arial;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: #333;
}

.generic-pages__section .generic-pages__text {
  padding: 0;
  font-size: 14px;
  font-family: Helvetica, Arial;
  letter-spacing: 0.25px;
  line-height: 22px;
  color: #333;
}

.generic-pages__header-container {
  margin: 20px 0 60px;
}

.generic-pages__table-container {
  margin: 15px 0;
}

.generic-pages__table-container table {
  width: 100%;
  max-width: 765px;
  padding: 30px;
  border-collapse: separate;
  border: 1px solid #101010;
}

@media screen and (max-width: 760px) {
  .generic-pages__table-container table {
    margin: 0 auto;
  }
}

.generic-pages__table-container table thead {
  background: none;
}

.generic-pages__table-container table th,
.generic-pages__table-container table td {
  padding-right: 20px;
  text-align: left;
}

.generic-pages__table-container table th {
  border-bottom: 1px solid #101010;
  text-transform: uppercase;
}

.generic-pages__table-container table td {
  padding: 10px 0px;
  border-bottom: 1px solid #ccc;
}

.generic-pages__table-container table td:nth-child(2) {
  vertical-align: middle;
}

.generic-pages__table-container table td span {
  color: #999;
}

.generic-pages__table-container table td span.returns-cc-refund-time-text,
.generic-pages__table-container table td span.returns-cc-refund-time-note {
  color: #101010;
}

.generic-pages__table-container table td .returns-cc-refund-time-note {
  font-size: 12px;
}

.generic-pages__table-container table tr:last-child td {
  padding-bottom: 0px;
  border: none;
}

.generic-pages__table-footer {
  padding-left: 31px;
}

/* h1 {
  font-size: 24px;
  line-height: 34px;
} */

h2 {
  font-size: 22px;
  line-height: 32px;
  font-weight: bold;
}

.sizes {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 0.8em;
}

.size {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
  overflow: hidden;
  /* margin-right: 2px; */
  /* border: 1px solid #aab8c2; */
  /* background-color: #aab8c2; */
  /* background-color: black; */
  /* color: white; */
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: default;
}
